import { useEffect, useState, useCallback } from "react"

export type OnlineState = "online" | "offline"
interface UseOnlineState {
  onlineState: OnlineState
}

function getInitialState(): OnlineState {
  if (typeof navigator !== "undefined") {
    return navigator.onLine ? "online" : "offline"
  }
  return "online"
}

export const useOnlineState = (): UseOnlineState => {
  const [state, setState] = useState<OnlineState>(getInitialState())

  const onOnline = useCallback(() => {
    setState("online")
  }, [setState])

  const onOffline = useCallback(() => {
    setState("offline")
  }, [setState])

  useEffect(() => {
    window.addEventListener('offline', onOffline)
    window.addEventListener('online', onOnline)
    return (): void => {
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [])

  return { onlineState: state, }
}
