import gql from "graphql-tag"
import _get from "lodash/get"

import { API } from "../../../lib/api/client"
import { UserPrediction, PredictionSpread, Scenario, ScenarioCategory } from "../../../types.gen"
import { ScenarioFragmentFullGQL } from "../fragments"
import { AnalyticsService, CustomEvent } from "../../../lib/analytics"


export const ScenarioQuery = gql`
  query Scenario($id: ID!) {
    scenario(id: $id) {
      ...ScenarioFragmentFull
    }
  }
  ${ScenarioFragmentFullGQL}
`

export const ScenariosQuery = gql`
  query PublicScenarios {
    publicScenarios {
      ...ScenarioFragmentFull
    }
  }
  ${ScenarioFragmentFullGQL}
`

export const ScenarioCategoriesQuery = gql`
  query ScenarioCategories {
    scenarioCategories {
      id
      title
    }
  }
`

export const UpdateScenarioResponseMutation = gql`
  mutation UpdateScenarioResponse($data: UpdateScenarioResponseSimpleInput!) {
    updateScenarioResponseSimple(data: $data) {
      id
    }
  }
`

export const ScenarioPredictionsQuery = gql`
  query ScenarioPredictions($id: ID!) {
    scenario(id: $id) {
      userPrediction {
        id
        scenarioOption {
          id
        }
        dateCreated
        confidence
      }
    }
  }
`

export const UserPredictionsQuery = gql`
  query UserPredictions {
    userPredictions {
      id
      scenario {
        id
      }
      scenarioOption {
        id
      }
      dateCreated
      confidence
    }
  }
`

export const ScenarioPredictionSpreadQuery = gql`
  query ScenarioPredictionSpread($id: ID!) {
    scenarioPredictionSpread(id: $id) {
      totalPredictionCount
      options {
        id
        predictionCount
      }
    }
  }
`

class Service {

  getScenario = async (scenarioId: string): Promise<Maybe<Scenario>> => {
    const response = await API.query(ScenarioQuery, {
      id: scenarioId
    }, "network-only")
    return _get(response, 'data.scenario', null)
  }

  getScenarios = async (forceFetch: boolean = false): Promise<Scenario[]> => {
    const response = await API.query(ScenariosQuery, {}, forceFetch ? "network-only" : "cache-first")
    return _get(response, 'data.publicScenarios', [])
  }

  getPredictions = async (forceFetch: boolean = false): Promise<UserPrediction[]> => {
    const response = await API.query(UserPredictionsQuery, {}, forceFetch ? "network-only" : "cache-first")
    return _get(response, 'data.userPredictions', [])
  }

  getPredictionsForScenario = async (scenarioId: string, forceFetch: boolean = false): Promise<Maybe<UserPrediction>> => {
    const response = await API.query(ScenarioPredictionsQuery, {
      id: scenarioId
    }, forceFetch ? "network-only" : "cache-first")
    return _get(response, 'data.scenario.userPrediction', null)
  }

  getPredictionSpread = async (scenarioId: string, forceFetch: boolean = false): Promise<Maybe<PredictionSpread>> => {
    const response = await API.query(ScenarioPredictionSpreadQuery, {
      id: scenarioId
    }, forceFetch ? "network-only" : "cache-first")
    return _get(response, 'data.scenarioPredictionSpread', null)
  }

  getCategories = async (forceFetch: boolean = false): Promise<ScenarioCategory[]> => {
    const response = await API.query(ScenarioCategoriesQuery, {}, forceFetch ? "network-only" : "cache-first")
    return _get(response, 'data.scenarioCategories', null)
  }

  createPrediction = async (scenarioId: string, scenarioOptionId: string, confidence: number): Promise<string> => {
    AnalyticsService.logEvent(CustomEvent.MakePrediction)
    const response = await API.mutate(UpdateScenarioResponseMutation, {
      data: {
        scenarioId,
        scenarioOptionId,
        confidence
      }
    })
    return _get(response, 'data.updateScenarioResponseSimple.id', '')
  }

}

export const ScenarioService = new Service()