import { AnalyticsService } from "../../src/lib/analytics"

const sendPageView = (location: Location) => {
  const pagePath = location.pathname + location.search + location.hash
  AnalyticsService.logPageView(pagePath)
}

const onRouteUpdate = ({ location }: { location: Location }) => {
  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  // Minimum delay for reactHelmet's requestAnimationFrame
  setTimeout(() => sendPageView(location), 32)

  return null
}

export default onRouteUpdate