import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"

import "../../src/theme/global.css"

import { Client as ApolloClient } from "../../src/lib/api/client"
import { AppContainer } from "../../src/components/AppContainer"

const wrapRootElement = ({ element }: any): JSX.Element => {
  return (
    <ApolloProvider client={ApolloClient}>
      <AppContainer>{element}</AppContainer>
    </ApolloProvider>
  )
}

export default wrapRootElement
