import firebase from "gatsby-plugin-firebase"
import gql from "graphql-tag"

import { isClient, delay } from "../../../lib/utils"
import { API } from "../../../lib/api/client"
import { setAuthToken } from "../../../lib/api/links";

export type User = firebase.User

export const UnsubscribeFromEmails = gql`
  mutation UnsubscribeFromEmails($data: UnsubscribeFromEmailsInput!) {
    unsubscribeFromEmails(data: $data) {
      id
    }
  }
`

export const LinkAnonymousAccount = gql`
  mutation LinkAnonymousAccount($data: LinkAnonymousAccountInput!) {
    linkAnonymousAccount(data: $data) {
      id
    }
  }
`

class Service {

  signInEmailAddress = ''
  signInSuccessUrl = ''

  refreshIdToken() {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      currentUser.getIdToken(true)
    }
  }

  signInAnonymously = async (): Promise<void> => {
    await firebase.auth().signInAnonymously()
  }

  sendSignInLink = async (emailAddress: string, successUrl: string): Promise<void> => {
    this.signInEmailAddress = emailAddress
    this.signInSuccessUrl = successUrl
    await firebase.auth().sendSignInLinkToEmail(emailAddress, {
      url: successUrl,
      handleCodeInApp: true,
    })
    if (isClient()) {
      window.localStorage.setItem("emailForSignIn", emailAddress)
    }
  }

  resendSignInLink = async (): Promise<void> => {
    if (this.signInEmailAddress && this.signInSuccessUrl) {
      this.sendSignInLink(this.signInEmailAddress, this.signInSuccessUrl)
    }
  }

  // https://firebase.google.com/docs/auth/web/google-signin
  // signInWithPopup = async (provider: firebase.auth.AuthProvider): Promise<void> => {
  //   firebase.auth().signInWithPopup(provider).then(function (result) {
  //     // This gives you a Google Access Token. You can use it to access the Google API.
  //     var token = result.credential.accessToken;
  //     // The signed-in user info.
  //     var user = result.user;
  //     // ...
  //   }).catch(function (error) {
  //     // Handle Errors here.
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //     // The email of the user's account used.
  //     var email = error.email;
  //     // The firebase.auth.AuthCredential type that was used.
  //     var credential = error.credential;
  //     // ...
  //   });
  // }

  completeLogin = async (): Promise<void> => {
    let email
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        if (isClient()) {
          email = window.prompt('Please provide your email for confirmation');
        }
      }
      if (!email) {
        throw new Error("Could not confirm email")
      }

      const currentUser = firebase.auth().currentUser
      if (currentUser) {
        const anonymousBearerToken = await currentUser.getIdToken()
        // Construct the email link credential from the current URL.
        const credential = firebase.auth.EmailAuthProvider.credentialWithLink(email, window.location.href);
        // Link the credential to the current user.
        try {
          await currentUser.linkWithCredential(credential)
        } catch (err) {
          if (err.code === "auth/email-already-in-use") {
            this.handleAnonymousUpgrade(anonymousBearerToken, credential)
          } else {
            throw err
          }
        }
      } else {
        // The client SDK will parse the code from the link for you.
        await firebase.auth().signInWithEmailLink(email, window.location.href)
      }

      // Clear email from storage.
      if (isClient()) {
        window.localStorage.removeItem('emailForSignIn');
      }
    } else {
      throw new Error("Missing sign in link")
    }
  }

  handleAnonymousUpgrade = async (anonymousBearerToken: string, credential: firebase.auth.AuthCredential): Promise<void> => {
    const signedInCredential = await firebase.auth().signInWithCredential(credential)
    if (signedInCredential && signedInCredential.user) {
      const newToken = await signedInCredential.user.getIdToken()
      setAuthToken(newToken)
    } else {
      await delay(500)
    }
    await this.linkAnonymousAccount(anonymousBearerToken)
  }

  unsubscribe = async (unsubscribeToken: string): Promise<void> => {
    await API.mutate(UnsubscribeFromEmails, {
      data: {
        unsubscribeToken
      }
    })
  }

  linkAnonymousAccount = async (anonymousBearerToken: string): Promise<void> => {
    await API.mutate(LinkAnonymousAccount, {
      data: {
        anonymousBearerToken
      }
    })
  }

  logout = async (): Promise<void> => {
    await firebase.auth().signOut()
    await this.signInAnonymously()
  }

}

export const AuthService = new Service()