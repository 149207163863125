import { createContainer } from "unstated-next"

import {
  useEmailSubscribe,
  UseEmailSubscribe,
  defaultState as emailSubscribeDefautState,
} from "./useEmailSubscribe"
import {
  useIntendedRecipient,
  UseIntendedRecipient,
  defaultState as intendedRecipientDefautState,
} from "./useIntendedRecipient"
import { isClient } from "../../../lib/utils"

interface SubscribeState {
  emailSubscribeState: UseEmailSubscribe
  intendedRecipientState: UseIntendedRecipient
}

export const defaultState: SubscribeState = {
  emailSubscribeState: emailSubscribeDefautState,
  intendedRecipientState: intendedRecipientDefautState,
}

function useContainerState(): SubscribeState {
  const emailSubscribeState = useEmailSubscribe()
  const intendedRecipientState = useIntendedRecipient()
  return {
    emailSubscribeState,
    intendedRecipientState,
  }
}

const Container = createContainer(useContainerState)
export const SubscribeStateProvider = Container.Provider
export const useSubscribeState = () => {
  if (isClient()) {
    return Container.useContainer()
  } else {
    return defaultState
  }
}
