import { useEffect, useCallback, useRef } from "react"

import { useOnlineState } from "../../../hooks"
import { AuthService } from "../services/Auth.service"

const AUTH_TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000

export const useRefreshAuthToken = (): void => {
  const { onlineState, } = useOnlineState()
  const prevOnlineStateRef = useRef(onlineState)

  const getNewAuthToken = useCallback(() => {
    AuthService.refreshIdToken()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNewAuthToken()
    }, AUTH_TOKEN_REFRESH_INTERVAL)
    return (): void => clearInterval(intervalId)
  }, [getNewAuthToken])

  useEffect(() => {
    if (prevOnlineStateRef.current !== onlineState) {
      if (onlineState === "online") {
        getNewAuthToken()
      }
      prevOnlineStateRef.current = onlineState
    }
  }, [onlineState, prevOnlineStateRef])
}
