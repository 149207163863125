import { useCallback, useReducer } from "react"

import { UserPrediction } from "../../../types.gen"
import { ScenarioService } from "../services/Scenario.service"
import { useAuthState } from "../../../features/Auth/state"
import { listDefaultState, predictionListReducer } from "./reducers"

export interface UsePredictionList {
  isFetchingList: boolean
  isFetchingListItem: { [key: string]: boolean }
  predictions: UserPrediction[]
  listFetchCount: number
  onFetchPredictions: (forceFetch?: boolean) => Promise<void>
  onFetchPredictionForScenario: (
    scenarioId: string,
    forceFetch?: boolean
  ) => Promise<void>
}

export const defaultState: UsePredictionList = {
  isFetchingList: false,
  isFetchingListItem: {},
  predictions: [],
  listFetchCount: 0,
  onFetchPredictions: async (forceFetch?: boolean) => {
    console.log("onFetchPredictions", forceFetch)
  },
  onFetchPredictionForScenario: async (
    scenarioId: string,
    forceFetch?: boolean
  ) => {
    console.log("onFetchPredictionForScenario", scenarioId, forceFetch)
  },
}

export const usePredictionList = (): UsePredictionList => {
  const { initialising } = useAuthState()
  const [state, dispatch] = useReducer(predictionListReducer, listDefaultState)
  const {
    isFetchingList,
    isFetchingListItem,
    listItems: predictions,
    listFetchCount,
  } = state

  const onFetchPredictions = useCallback(
    async (forceFetch?: boolean): Promise<void> => {
      dispatch({
        type: "LIST_FETCH_START",
      })
      const predictionList = await ScenarioService.getPredictions(forceFetch)
      dispatch({
        type: "LIST_FETCH_SUCCESS",
        data: predictionList,
      })
    },
    [dispatch]
  )

  const onFetchPredictionForScenario = useCallback(
    async (scenarioId: string, forceFetch?: boolean): Promise<any> => {
      dispatch({
        type: "LIST_FETCH_ITEM_START",
        id: scenarioId,
      })
      const prediction = await ScenarioService.getPredictionsForScenario(
        scenarioId,
        forceFetch
      )
      dispatch({
        type: "LIST_FETCH_ITEM_SUCCESS",
        data: prediction
          ? {
              scenario: {
                id: scenarioId,
              },
              ...prediction,
            }
          : null,
        id: scenarioId,
      })
    },
    [dispatch]
  )

  return {
    listFetchCount,
    isFetchingList,
    isFetchingListItem,
    predictions,
    onFetchPredictions,
    onFetchPredictionForScenario,
  }
}
