import { ApolloLink } from "apollo-link"

let _authToken = '';

export const setAuthToken = (token: string) => _authToken = token

export const authLink = () =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: { Authorization: _authToken ? `Bearer ${_authToken}` : null, },
      ...operation.getContext().headers,
    })
    return forward(operation)
  })
