import { Reducer } from "react"
import _findIndex from "lodash/findIndex"

export const listDefaultState = {
  listItems: [],
  isFetchingList: false,
  isFetchingListItem: {},
  listFetchCount: 0
}

interface ListReducerState<T> {
  listItems: T[]
  isFetchingList: boolean
  isFetchingListItem: { [key: string]: boolean }
  listFetchCount: number
}


interface ListActionFetchStart {
  type: "LIST_FETCH_START",
}
interface ListActionFetchSuccess<T> {
  type: "LIST_FETCH_SUCCESS"
  data: T[]
}
interface ListActionFetchError {
  type: "LIST_FETCH_ERROR"
}

interface ListActionFetchItemStart {
  type: "LIST_FETCH_ITEM_START",
  id: string
}
interface ListActionFetchItemSuccess<T> {
  type: "LIST_FETCH_ITEM_SUCCESS"
  id: string
  data: Maybe<T>
}
interface ListActionFetchItemError {
  type: "LIST_FETCH_ITEM_ERROR"
  id: string
}


export type ListReducerAction<T> = |
  ListActionFetchStart |
  ListActionFetchSuccess<T> |
  ListActionFetchError |
  ListActionFetchItemStart |
  ListActionFetchItemSuccess<T> |
  ListActionFetchItemError

export const createListReducer = <T>(idProperty: string): Reducer<ListReducerState<T>, ListReducerAction<T>> => {
  const listReducer = (state: ListReducerState<T>, action: ListReducerAction<T>): ListReducerState<T> => {
    let indexOfItem = -1

    switch (action.type) {
      case "LIST_FETCH_START":
        return {
          ...state,
          isFetchingList: true
        }
      case "LIST_FETCH_ITEM_ERROR":
        return {
          ...state,
          isFetchingList: false
        }
      case "LIST_FETCH_SUCCESS":
        return {
          ...state,
          listItems: [
            ...action.data
          ],
          isFetchingList: false,
          isFetchingListItem: {},
          listFetchCount: state.listFetchCount += 1
        }

      case "LIST_FETCH_ITEM_START":
        return {
          ...state,
          isFetchingListItem: {
            ...state.isFetchingListItem,
            [action.id]: true
          }
        }
      case "LIST_FETCH_ITEM_ERROR":
        return {
          ...state,
          isFetchingListItem: {
            ...state.isFetchingListItem,
            [action.id]: false
          }
        }
      case "LIST_FETCH_ITEM_SUCCESS":
        indexOfItem = _findIndex(state.listItems, [idProperty, action.id])
        if (indexOfItem === -1) {
          if (action.data) {
            return {
              ...state,
              listItems: [
                ...state.listItems,
                action.data,
              ],
              isFetchingListItem: {
                ...state.isFetchingListItem,
                [action.id]: false
              }
            }
          }
        } else {
          if (action.data) {
            return {
              ...state,
              listItems: [
                ...state.listItems.slice(0, indexOfItem),
                action.data,
                ...state.listItems.slice(indexOfItem + 1)
              ],
              isFetchingListItem: {
                ...state.isFetchingListItem,
                [action.id]: false
              }
            }
          } else {
            return {
              ...state,
              listItems: [
                ...state.listItems.slice(0, indexOfItem),
                ...state.listItems.slice(indexOfItem + 1)
              ],
              isFetchingListItem: {
                ...state.isFetchingListItem,
                [action.id]: false
              }
            }
          }
        }

      default:
        return state
    }
  }
  return listReducer
}