import queryString from "query-string"

export const isClient = (): boolean => typeof window === "object"

export const delay = (ms = 500): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const getQueryStringParam = (location: Location, param: string): Maybe<string> => {
  const query = queryString.parse(location.search)
  const paramValue = query[param]
  if (typeof paramValue === "string") {
    return paramValue
  }
  return null
}

export const getPathWithoutQueryParam = (location: Location, param: string): string => {
  const href = location.href.replace(location.origin, "")
  const paramValue = getQueryStringParam(location, param)
  if (paramValue) {
    const paramString = `${param}=${paramValue}`
    if (href.indexOf(`?${paramString}`) > -1) {
      return href.replace(`?${paramString}`, "")
    } else {
      return href.replace(`&${paramString}`, "")
    }
  }
  return href
}

export const pluralise = (textSingular: string, textPlural: string, value: number): string => {
  if (value === 1) {
    return textSingular
  }
  return textPlural
}