import { useState, useCallback, useEffect } from "react"
import { navigateTo } from "gatsby"

import {
  isClient,
  getPathWithoutQueryParam,
  getQueryStringParam,
} from "../../../lib/utils"
import { useAuthState } from "../../Auth/state"

export interface UseIntendedRecipient {
  intendedRecipient: string
  continueTo: string
  isShowLogin: boolean
  onCloseLogin: () => void
  onSetIntendedRecipient: (emailAddress: string) => void
}

export const defaultState: UseIntendedRecipient = {
  intendedRecipient: "",
  continueTo: "",
  isShowLogin: false,
  onCloseLogin: () => {
    console.log("onCloseLogin")
  },
  onSetIntendedRecipient: (emailAddress: string) => {
    console.log("onSetIntendedRecipient", emailAddress)
  },
}

export const useIntendedRecipient = (): UseIntendedRecipient => {
  const [intendedRecipient, onSetIntendedRecipient] = useState("")
  const continueTo = isClient()
    ? getPathWithoutQueryParam(window.location, "recipient")
    : ""
  const { initialising, emailAddress } = useAuthState()

  const [isShowLogin, setIsShowLogin] = useState(false)

  const onCloseLogin = useCallback(() => {
    setIsShowLogin(false)
    if (isClient()) {
      navigateTo(continueTo)
    }
  }, [continueTo, setIsShowLogin])

  useEffect(() => {
    if (!initialising && !!intendedRecipient) {
      if (emailAddress !== intendedRecipient) {
        setIsShowLogin(true)
      }
    }
  }, [initialising, intendedRecipient, emailAddress, setIsShowLogin])

  useEffect(() => {
    if (isClient()) {
      const recipient = getQueryStringParam(window.location, "recipient")
      if (recipient) {
        onSetIntendedRecipient(recipient)
      }
    }
  }, [onSetIntendedRecipient])

  return {
    intendedRecipient,
    continueTo,
    isShowLogin,
    onCloseLogin,
    onSetIntendedRecipient,
  }
}
