import { useEffect, useState } from "react"

import { useAuthState } from "../../Auth/state"
import { usePreviousValue } from "../../../hooks"

export const useRefetchDataOnAuth = (onUserChange: () => void) => {
  const { initialising, userId } = useAuthState()
  const previousUserId = usePreviousValue(userId)

  // fetch content on initialisation
  useEffect(() => {
    if (!initialising) {
      onUserChange()
    }
  }, [initialising])

  // refetch content when users change
  useEffect(() => {
    if (userId && previousUserId && userId !== previousUserId) {
      onUserChange()
    }
  }, [initialising, userId, previousUserId])
}
