import { useState, useCallback, useEffect } from "react"

import { AuthService } from "../../Auth/services/Auth.service"
// import { useAuthState } from "../../Auth/state"
// import { AnalyticsService, CustomEvent } from "../../../lib/analytics"
// import { useScenarioState } from "../../Scenario/state"

export interface UseEmailSubscribe {
  emailAddress: string
  // isSendingSubscribeEmail: boolean
  // hasSentSubscribeEmail: boolean
  // isSubscribing: boolean
  // hasSubscribed: boolean
  isUnsubscribing: boolean
  hasUnsubscribed: boolean
  onChangeEmailAddress: (emailAddress: string) => void
  // onSendSubscribeEmail: (emailAddress: string, url: string) => Promise<void>
  // onResendSubscribeEmail: () => Promise<void>
  // onSubscribeConfirm: () => Promise<void>
  onUnsubscribe: (token: string) => Promise<void>
}

export const defaultState: UseEmailSubscribe = {
  emailAddress: '',
  // isSendingSubscribeEmail: false,
  // hasSentSubscribeEmail: false,
  // isSubscribing: false,
  // hasSubscribed: false,
  isUnsubscribing: false,
  hasUnsubscribed: false,
  onChangeEmailAddress: (emailAddress: string) => {
    console.log("onChangeEmailAddress", emailAddress)
  },
  // onSendSubscribeEmail: async (emailAddress: string, url: string) => {
  //   console.log("onSendSubscribeEmail", emailAddress, url)
  // },
  // onResendSubscribeEmail: async () => {
  //   console.log("onResendSubscribeEmail")
  // },
  // onSubscribeConfirm: async () => {
  //   console.log('onSubscribeConfirm')
  // },
  onUnsubscribe: async (token: string) => {
    console.log('onUnsubscribe', token)
  }
}

export const useEmailSubscribe = (): UseEmailSubscribe => {
  // const { scenarioListState: {
  //   onFetchScenarios
  // }, predictionListState: {
  //   onFetchPredictions
  // } } = useScenarioState()

  const [emailAddress, setEmailAddress] = useState('')
  // const [isSendingSubscribeEmail, setIsSendingSubscribeEmail] = useState(false)
  // const [hasSentSubscribeEmail, setHasSentSubscribeEmail] = useState(false)
  // const [isSubscribing, setIsSubscribing] = useState(false)
  // const [hasSubscribed, setHasSubscribed] = useState(false)
  const [isUnsubscribing, setIsUnsubscribing] = useState(false)
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false)

  const onChangeEmailAddress = useCallback((emailAddress: string): void => {
    setEmailAddress(emailAddress)
  }, [setEmailAddress])

  // const onSendSubscribeEmail = useCallback(async (email: string, continueTo: string): Promise<void> => {
  //   AnalyticsService.logEvent(CustomEvent.StartSubscription)
  //   setIsSendingSubscribeEmail(true)
  //   try {
  //     if (email && emailAddress !== email) {
  //       setEmailAddress(email)
  //     }
  //     await AuthService.sendSignInLink(email ? email : emailAddress, continueTo)
  //     setHasSentSubscribeEmail(true)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   setIsSendingSubscribeEmail(false)
  // }, [emailAddress, setIsSendingSubscribeEmail, setHasSentSubscribeEmail, setEmailAddress])

  // const onResendSubscribeEmail = useCallback(async () => {
  //   setIsSendingSubscribeEmail(true)
  //   try {
  //     await AuthService.resendSignInLink()
  //     setHasSentSubscribeEmail(true)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   setIsSendingSubscribeEmail(false)
  // }, [setIsSendingSubscribeEmail, setHasSentSubscribeEmail])

  // const onSubscribeConfirm = useCallback(async (): Promise<void> => {
  //   AnalyticsService.logEvent(CustomEvent.CompleteSubscription)
  //   setIsSubscribing(true)
  //   try {
  //     await AuthService.completeLogin()
  //     await Promise.all([
  //       onFetchScenarios(true),
  //       onFetchPredictions(true)
  //     ])
  //     setHasSubscribed(true)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   setIsSubscribing(false)
  // }, [setIsSubscribing, setHasSubscribed])

  const onUnsubscribe = useCallback(async (unsubscribeToken: string): Promise<void> => {
    setIsUnsubscribing(true)
    try {
      await AuthService.unsubscribe(unsubscribeToken)
    } catch (err) {
    }
    setIsUnsubscribing(false)
  }, [setIsUnsubscribing, setHasUnsubscribed])

  return {
    emailAddress,
    // isSendingSubscribeEmail,
    // hasSentSubscribeEmail,
    // isSubscribing,
    // hasSubscribed,
    isUnsubscribing,
    hasUnsubscribed,
    onChangeEmailAddress,
    // onSendSubscribeEmail,
    // onResendSubscribeEmail,
    // onSubscribeConfirm,
    onUnsubscribe,
  }
}