import firebase from "gatsby-plugin-firebase"

export enum CustomEvent {
  MakePrediction = "MakePrediction",
  SelectScenarioOutcomeOption = "SelectScenarioOutcomeOption",
  // StartSubscription = "StartSubscription",
  IntendedRecipientSignIn = "IntendedRecipientSignIn",
  ScenarioPredictionSignIn = "ScenarioPredictionSignIn",
  CompleteSubscription = "CompleteSubscription",
}

export enum UserProperty {
  anonymous = "anonymous",
  environment = "environment",
  development = "development"
}

export class AnalyticsService {

  static initialise() {
    firebase.analytics()
  }

  static setUserId(userId: string) {
    firebase.analytics().setUserId(userId)
  }

  static setUserProperty(property: UserProperty, value: any) {
    firebase.analytics().setUserProperties({
      [property]: value
    })
  }

  static logPageView(pageName: string) {
    firebase.analytics().setCurrentScreen(pageName)
  }

  static logEvent(event: CustomEvent) {
    firebase.analytics().logEvent(event)
  }

}