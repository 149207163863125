import { createContainer } from "unstated-next"

import {
  defaultState as defaultScenarioListState,
  useScenarioList,
  UseScenarioList,
} from "./useScenarioList"
import { isClient } from "../../../lib/utils"
import {
  defaultState as defaultPredictionListState,
  usePredictionList,
  UsePredictionList,
} from "./usePredictionList"
import {
  defaultState as defaultPredictionSpreadListState,
  usePredictionSpreadList,
  UsePredictionSpreadList,
} from "./usePredictionSpreadList"
import {
  defaultState as defaultCategoryListState,
  useCategoryList,
  UseCategoryList,
} from "./useCategoryList"
import { useRefetchDataOnAuth } from "./useRefetchDataOnAuth"

interface ScenarioState {
  scenarioListState: UseScenarioList
  predictionListState: UsePredictionList
  predictionSpreadListState: UsePredictionSpreadList
  categoryListState: UseCategoryList
}

export const defaultState: ScenarioState = {
  scenarioListState: defaultScenarioListState,
  predictionListState: defaultPredictionListState,
  predictionSpreadListState: defaultPredictionSpreadListState,
  categoryListState: defaultCategoryListState,
}

function useContainerState(): ScenarioState {
  const scenarioListState = useScenarioList()
  const predictionListState = usePredictionList()
  const predictionSpreadListState = usePredictionSpreadList()
  const categoryListState = useCategoryList()

  useRefetchDataOnAuth(() => {
    predictionSpreadListState.onResetState()
    scenarioListState.onFetchScenarios(true)
    predictionListState.onFetchPredictions(true)
  })

  return {
    scenarioListState,
    predictionListState,
    predictionSpreadListState,
    categoryListState,
  }
}

const Container = createContainer(useContainerState)
export const ScenarioStateProvider = Container.Provider
export const useScenarioState = () => {
  if (isClient()) {
    return Container.useContainer()
  } else {
    return defaultState
  }
}
