import { ApolloClient, FetchPolicy, ApolloQueryResult } from 'apollo-client';
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloLink, FetchResult } from 'apollo-link';
import fetch from 'isomorphic-fetch';

import { setAuthToken, httpLink, authLink } from './links'

export const Client = new ApolloClient({
  fetch,
  link: ApolloLink.from([
    authLink(),
    httpLink(),
  ]),
  cache: new InMemoryCache()
});

export class API {
  static setAuthToken = (token: string) => {
    setAuthToken(token);
  }
  static query(
    query: any,
    variables: object = {},
    fetchPolicy: FetchPolicy = 'no-cache',
  ): Promise<ApolloQueryResult<any>> {
    return Client.query({
      query,
      variables,
      fetchPolicy,
    });
  }

  static mutate(
    mutation: any,
    variables: object = {},
    optimisticResponse: object = {},
  ): Promise<FetchResult<any>> {
    return Client.mutate({
      mutation,
      variables,
      optimisticResponse,
    });
  }
}
