import React, { useEffect } from "react"
import * as Sentry from "@sentry/browser"

import { AuthStateProvider } from "../../features/Auth/state"
import { ScenarioStateProvider } from "../../features/Scenario/state"
import { SubscribeStateProvider } from "../../features/Subscribe/state"
import { AnalyticsService, UserProperty } from "../../lib/analytics"
import { IS_DEVELOP_MODE } from "../../lib/config"

export const AppContainer = ({ children }: any): JSX.Element => {
  useEffect(() => {
    AnalyticsService.initialise()
    AnalyticsService.setUserProperty(
      UserProperty.development,
      process.env.NODE_ENV === "development"
    )
    AnalyticsService.setUserProperty(
      UserProperty.environment,
      process.env.TIPSTA_ENV
    )

    if (!IS_DEVELOP_MODE) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        enabled: true,
        environment: process.env.TIPSTA_ENV,
      })
    }
  }, [])
  return (
    <AuthStateProvider>
      <ScenarioStateProvider>
        <SubscribeStateProvider>{children}</SubscribeStateProvider>
      </ScenarioStateProvider>
    </AuthStateProvider>
  )
}
