import { useCallback, useReducer } from "react"

import { ScenarioService } from "../services/Scenario.service"
import {
  listDefaultState,
  predictionSpreadListReducer,
  ScenarioPredictionSpread,
} from "./reducers"

export interface UsePredictionSpreadList {
  isFetchingListItem: { [key: string]: boolean }
  predictionSpreads: ScenarioPredictionSpread[]
  onFetchPredictionSpreadForScenario: (
    scenarioId: string,
    forceFetch?: boolean
  ) => Promise<void>
  onResetState: () => void
}

export const defaultState: UsePredictionSpreadList = {
  isFetchingListItem: {},
  predictionSpreads: [],
  onFetchPredictionSpreadForScenario: async (
    scenarioId: string,
    forceFetch?: boolean
  ) => {
    console.log("onFetchPredictionForScenario", scenarioId, forceFetch)
  },
  onResetState: () => {
    console.log("onResetState")
  },
}

export const usePredictionSpreadList = (): UsePredictionSpreadList => {
  const [state, dispatch] = useReducer(
    predictionSpreadListReducer,
    listDefaultState
  )
  const { isFetchingListItem, listItems: predictionSpreads } = state

  const onFetchPredictionSpreadForScenario = useCallback(
    async (scenarioId: string, forceFetch?: boolean): Promise<any> => {
      dispatch({
        type: "LIST_FETCH_ITEM_START",
        id: scenarioId,
      })
      const predictionSpread = await ScenarioService.getPredictionSpread(
        scenarioId,
        forceFetch
      )
      dispatch({
        type: "LIST_FETCH_ITEM_SUCCESS",
        data: predictionSpread
          ? {
              scenarioId,
              ...predictionSpread,
            }
          : null,
        id: scenarioId,
      })
    },
    [dispatch]
  )

  const onResetState = useCallback(() => {
    dispatch({
      type: "LIST_FETCH_SUCCESS",
      data: [],
    })
  }, [dispatch])

  return {
    isFetchingListItem,
    predictionSpreads,
    onFetchPredictionSpreadForScenario,
    onResetState,
  }
}
