import { useCallback, useReducer } from "react"

import { Scenario } from "../../../types.gen"
import { ScenarioService } from "../services/Scenario.service"
import { useAuthState } from "../../../features/Auth/state"
import { scenarioListReducer, listDefaultState } from "./reducers"

export interface UseScenarioList {
  isFetchingList: boolean
  isFetchingListItem: { [key: string]: boolean }
  scenarios: Scenario[]
  listFetchCount: number
  onFetchScenarios: (forceFetch?: boolean) => Promise<void>
  onFetchScenario: (scenarioId: string) => Promise<void>
}

export const defaultState: UseScenarioList = {
  isFetchingList: false,
  isFetchingListItem: {},
  scenarios: [],
  listFetchCount: 0,
  onFetchScenarios: async (forceFetch?: boolean) => {
    console.log("onFetchScenarios", forceFetch)
  },
  onFetchScenario: async (scenarioId: string) => {
    console.log("onFetchScenario", scenarioId)
  },
}

export const useScenarioList = (): UseScenarioList => {
  const { initialising } = useAuthState()
  const [state, dispatch] = useReducer(scenarioListReducer, listDefaultState)
  const { isFetchingList, isFetchingListItem, listItems: scenarios, listFetchCount } = state

  const onFetchScenarios = useCallback(async (forceFetch?: boolean): Promise<void> => {
    dispatch({
      type: "LIST_FETCH_START"
    })
    const scenarioList = await ScenarioService.getScenarios(forceFetch)
    dispatch({
      type: "LIST_FETCH_SUCCESS",
      data: scenarioList
    })
  }, [dispatch])

  const onFetchScenario = useCallback(async (scenarioId: string): Promise<any> => {
    dispatch({
      type: "LIST_FETCH_ITEM_START",
      id: scenarioId
    })
    const scenario = await ScenarioService.getScenario(scenarioId)
    dispatch({
      type: "LIST_FETCH_ITEM_SUCCESS",
      data: scenario,
      id: scenarioId
    })
  }, [dispatch])

  return {
    listFetchCount,
    isFetchingList,
    isFetchingListItem,
    scenarios,
    onFetchScenarios,
    onFetchScenario
  }
}