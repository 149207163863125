import { useCallback, useReducer, useEffect } from "react"

import { Scenario, ScenarioCategory } from "../../../types.gen"
import { ScenarioService } from "../services/Scenario.service"
import { useAuthState } from "../../../features/Auth/state"
import { scenarioListReducer, listDefaultState, categoryListReducer } from "./reducers"

export interface UseCategoryList {
  isFetchingList: boolean
  isFetchingListItem: { [key: string]: boolean }
  categories: ScenarioCategory[]
  listFetchCount: number
  onFetchCategories: (forceFetch?: boolean) => Promise<void>
}

export const defaultState: UseCategoryList = {
  isFetchingList: false,
  isFetchingListItem: {},
  categories: [],
  listFetchCount: 0,
  onFetchCategories: async (forceFetch?: boolean) => {
    console.log("onFetchCategories", forceFetch)
  },
}

export const useCategoryList = (): UseCategoryList => {
  const { initialising } = useAuthState()
  const [state, dispatch] = useReducer(categoryListReducer, listDefaultState)
  const { isFetchingList, isFetchingListItem, listItems: categories, listFetchCount } = state

  const onFetchCategories = useCallback(async (forceFetch?: boolean): Promise<void> => {
    dispatch({
      type: "LIST_FETCH_START"
    })
    const categoryList = await ScenarioService.getCategories(forceFetch)
    dispatch({
      type: "LIST_FETCH_SUCCESS",
      data: categoryList
    })
  }, [dispatch])

  useEffect(() => {
    if (!initialising) {
      onFetchCategories(true)
    }
  }, [initialising, onFetchCategories])

  return {
    listFetchCount,
    isFetchingList,
    isFetchingListItem,
    categories,
    onFetchCategories,
  }
}