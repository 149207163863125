/* 
 * SOMEHOW THIS IS PICKED UP AUTOMATICALLY BY GATSBY
 * AND SO FRAGMENTS DON'T NEED TO BE IMPORTED WHEN USED 
 */

import { graphql } from 'gatsby'
import gql from 'graphql-tag'

export const ScenarioFragmentFull = graphql`
  fragment ScenarioFragmentFull on Scenario {
    id
    title
    description
    dateCreated
    category {
      id
      title
    }
    options {
      id
      title
    }
    resolution {
      optionId
      effectiveDate
    }
    deepLink
    isAnswerable
  }
`

export const ScenarioFragmentFullGQL = gql`
  fragment ScenarioFragmentFull on Scenario {
    id
    title
    description
    dateCreated
    category {
      id
      title
    }
    options {
      id
      title
    }
    resolution {
      optionId
      effectiveDate
    }
    deepLink
    isAnswerable
  }
`

export const ScenarioFragmentListItem = graphql`
  fragment ScenarioFragmentListItem on Scenario {
    id
    title
  }
`