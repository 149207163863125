import { createContainer } from "unstated-next"

import { useAuth, UseAuth, defaultState as authDefaultState } from "./useAuth"
import { isClient } from "../../../lib/utils"

interface AuthState extends UseAuth {}

function useContainerState(): AuthState {
  const authState = useAuth()
  return {
    ...authState,
  }
}

export const defaultState: AuthState = {
  ...authDefaultState,
}

const Container = createContainer(useContainerState)
export const AuthStateProvider = Container.Provider
export const useAuthState = () => {
  if (isClient()) {
    return Container.useContainer()
  } else {
    return defaultState
  }
}
